import React from 'react'
import { css } from 'emotion'
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import { skillLevels } from '../../lib/formTagValues'

const SkillLevelInput = ({ value, title, checked, onChange, disabled }) => (
  <label
    htmlFor={value}
    className={css({
      display: 'inline-flex',
      alignItems: 'center',
      lineHeight: '1',
      fontSize: '16px',
      color: disabled ? 'hsl(0, 0%, 50%)' : 'black',
      cursor: disabled ? 'cursor' : 'pointer',
      fontWeight: '400',
      ['&:not(:first-child)']: {
        marginTop: '25px',
      },
    })}
  >
    <input
      type="radio"
      name="skill"
      id={value}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      value={value}
      className={css`
        width: 16px;
        height: 16px;
        margin-right: 6px;
      `}
    />
    <span>{title}</span>
  </label>
)

const SkillLevelFilter = ({ course, onChange }) => {
  const courseEmpty = isEmpty(course)
  const skillLevelList = map(get(course, 'skill_level_list', []), tag =>
    get(tag, 'name', tag),
  )

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        padding: 15px;
        border: 1px solid #ededed;
      `}
    >
      <form
        className={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {skillLevels.map((item, index) => (
          <SkillLevelInput
            value={item.value}
            title={item.title}
            key={index}
            disabled={courseEmpty}
            checked={includes(skillLevelList, item.value)}
            onChange={onChange}
          />
        ))}
      </form>
    </div>
  )
}

export default SkillLevelFilter
