import React from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import map from 'lodash/map'
import Select from 'react-select'

const concepts = [
  { value: 'functional-programming', label: 'Functional Programming' },
  { value: 'database', label: 'Database' },
  { value: 'array', label: 'Array' },
  { value: 'container', label: 'Container' },
  { value: 'monad', label: 'Monad' },
]

const ConceptsFilter = ({ course, onChange }) => {
  const skillsetList = map(get(course, 'skillset_list', []), tag => {
    const value = get(tag, 'name', get(tag, 'value'))
    const label = get(tag, 'name', get(tag, 'label'))
    return {
      value,
      label,
    }
  })
  return (
    <Select
      value={skillsetList}
      isMulti
      isDisabled={isEmpty(course)}
      isSearchable
      onChange={onChange}
      options={concepts}
    />
  )
}

export default ConceptsFilter
