import React from 'react'
import { css } from 'emotion'
import './index.css'


function backgroundColor({ blue, isDisabled }) {
  const saturation = isDisabled ? '50%' : '100%'
  const color = blue ? '0,101,242' : '255,255,255'

  return `rgba(${color}, ${saturation})`
}

const Button = ({ red, blue, white, isDisabled, children, ...props }) => (
  <button
    {...props}
    className={`
      ${red && 'red'}
      ${blue && 'blue'}
      ${white && 'white'}
      ${css`
        cursor: ${isDisabled ? 'cursor' : 'pointer'};
        background-color: ${backgroundColor({ blue, isDisabled })};
      `}
    `}
  >
    <span
      className={`
        ${white && 'white'}
        ${red && 'red'}
        ${blue && 'blue'}
      `}
    >
      {children}
    </span>
  </button>
)

export default Button
