import gql from 'graphql-tag'

export const courseMutation = gql`
  mutation UpdateCourse(
    $slug: String!
    $skillset_list: String!
    $skill_level_list: String!
  ) {
    update_course(
      slug: $slug
      skillset_list: $skillset_list
      skill_level_list: $skill_level_list
    ) {
      course {
        title
        slug
        square_cover_480_url
        skillset_list {
          name
        }
        skill_level_list {
          name
        }
      }
      errors {
        message
        path
      }
    }
  }
`
