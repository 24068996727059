import React from 'react'
import { css } from 'emotion'

const InputTitle = ({
  icon,
  title,
  defaultCase,
  marginBottom = 20,
  className,
}) => (
  <h3
    className={css`
      display: flex;
      align-items: center;
      text-transform: ${defaultCase ? 'none' : 'uppercase'};
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      margin-bottom: ${marginBottom}px;
      ${className ? className : ''};
    `}
  >
    <span
      role="img"
      className={css`
        font-size: 20px;
        margin-right: 6px;
      `}
    >
      {icon}
    </span>
    <span>{title}</span>
  </h3>
)

export default InputTitle
