import React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import Authentication from './Authentication'
import logo from '../images/eggo.png'

class Header extends React.Component {
  state = {
    loaded: false,
  }
  componentDidMount() {
    this.setState({
      loaded: true,
    })
  }
  render() {
    return (
      <div
        className={css`
          height: 60px;
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          max-width: 1440px;
          width: 90%;
          align-self: center;
          padding-bottom: 10px;
          margin-top: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        `}
      >
        <h1
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Link
            to="/"
            className={css`
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #000000;
            `}
          >
            <img
              src={logo}
              alt="Site Logo"
              height="50"
              aria-label="egghead review logo"
              className={css`
                padding-right: 5px;
              `}
            />
            egghead review
          </Link>
          <Authentication loaded={this.state.loaded} />
        </h1>
      </div>
    )
  }
}

export default Header
