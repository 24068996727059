const skillLevels = [
  {
    value: '1',
    title: 'True beginner (1)',
  },
  {
    value: '2',
    title: 'Beginner (2)',
  },
  {
    value: '3',
    title: 'Advanced beginner (3)',
  },
  {
    value: '4',
    title: 'Intermediate (4)',
  },
  {
    value: '5',
    title: 'Advanced (5)',
  },
  {
    value: '6',
    title: 'Advanced Plus (6)',
  },
  {
    value: '7',
    title: 'Expert (7)',
  },
  {
    value: '8',
    title: 'Superior (8)',
  },
  {
    value: '9',
    title: 'Master (9)',
  },
  {
    value: '10',
    title: 'Godlike (10)',
  },
]

export { skillLevels }
