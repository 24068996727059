import React from 'react'
import noop from '../lib/noop'

export const UserContext = React.createContext({
  authenticated: true,
  user: {},
  notification: null,
  setNotification: noop,
  clearNotification: noop,
})
