import React from 'react'
import { css } from 'emotion'

import Auth from '../../lib/utils/auth'
import { UserContext } from '../../context/userContext'
import LoginButton from './components/LoginButton'
import Button from '../Button'
const auth = new Auth()

class Login extends React.Component {
  render() {
    return (
      <UserContext.Consumer>
        {({ authenticated }) => {
          return (
            <div
              className={css`
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              `}
            >
              {this.props.loaded && (
                <>
                  {authenticated ? (
                    <Button
                      white
                      onClick={() => {
                        auth.logout()
                        window.location.reload()
                      }}
                    >
                      log out
                    </Button>
                  ) : (
                    <LoginButton />
                  )}
                </>
              )}
            </div>
          )
        }}
      </UserContext.Consumer>
    )
  }
}

export default Login
