const colorValues = {
  black: '#000',
  white: '#fff',
  transparent: 'transparent',
  'base-yellow': '#FFAA32',
  'yellow-lighten-15': '#FFD473',
  'gray-darken-20': '#32323B',
  'gray-darken-30': '#1B1B1F',
  'gray-lighten-40': '#E1E3E6',
  'gray-lighten-50': '#F3F5F7',
  'base-orange': '#FF8B66',
}

export default colorValues
