import React, { useState } from 'react'
import { css } from 'emotion'
import isEmpty from 'lodash/isEmpty'

import { bpMaxSM } from '../../lib/breakpoints'

import externalLinkIcon from './images/external-link.svg'

const SearchCourses = ({ value, onKeyDown, onChange }) => (
  <div
    className={css`
      display: flex;
      width: 100%;
      align-items: center;
      flex-shrink: 0;
      border-bottom: 1px solid #ededed;
    `}
  >
    <button
      type="button"
      className={css`
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: none;
        font-size: 16px;
        background: none;
        &:focus {
          outline: none;
        }
      `}
    >
      <span role="img" aria-label="search">
        👓
      </span>
    </button>
    <input
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      type="text"
      placeholder="Search courses"
      className={css`
        height: 48px;
        flex-grow: 1;
        border: none;
        box-shadow: none;
        font-size: 16px;
        &:focus {
          outline: none;
        }
      `}
    />
  </div>
)

const FilterInfo = ({ icon, value }) => (
  <div
    className={css`
      display: flex;
      align-items: center;
      font-size: 13px;
      &:not(:last-child) {
        margin: 0 25px 0 0;
        ${bpMaxSM} {
          margin: 0 0 10px 0;
        }
      }
    `}
  >
    <div
      className={css`
        margin-right: 3px;
      `}
    >
      {icon}
    </div>
    <div
      className={css`
        color: black;
        opacity: 0.5;
      `}
    >
      {value}
    </div>
  </div>
)

const CourseItem = ({ tagIcon, title, externalLink, onClick, isSelected }) => (
  <li
    onClick={onClick}
    className={css`
      pointer: ${isSelected ? 'cursor' : 'pointer'};
      background: ${isSelected ? '#f2f2f2' : ''};
      padding: 15px;
      transition: 150ms;
      min-height: 67px;
      &:not(:first-child) {
        border-top: 1px solid #ededed;
      }
      &:hover {
        background: #f2f2f2;
      }
    `}
  >
    <div
      className={css`
        display: flex;
        align-items: flex-start;
        width: 100%;
      `}
    >
      {tagIcon && (
        <img
          src={tagIcon}
          alt=""
          className={css`
            display: block;
            width: 18px;
            height: 18px;
            margin-right: 10px;
            flex-shrink: 0;
            align-self: flex-start;
            margin-top: 3px;
          `}
        />
      )}
      {title && (
        <h3
          className={css`
            font-size: 16px;
            font-weight: 500;
            line-height: 1.25;
            color: black;
            margin: 0;
            flex-grow: 1;
            &:not(:last-child) {
              margin-right: 10px;
            }
          `}
        >
          {title}
        </h3>
      )}
      {externalLink && (
        <a
          href={externalLink}
          className={css`
            display: block;
            flex-shrink: 0;
          `}
        >
          <img
            src={externalLinkIcon}
            alt=""
            className={css`
              display: block;
              width: 16px;
            `}
          />
        </a>
      )}
    </div>
    <div
      className={css`
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        ${bpMaxSM} {
          flex-direction: column;
          align-items: flex-start;
        }
      `}
    />
  </li>
)

const CoursesList = ({ courses, selectedCourse, setSelectedCourse }) => {
  const [filter, setFilter] = useState('')
  return (
    <div
      className={css`
        border: 1px solid #ededed;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <SearchCourses
        value={filter}
        onChange={e => setFilter(e.target.value)}
        onKeyDown={() => {
          if (filter == '') {
            setSelectedCourse()
          }
        }}
      />
      <div
        className={css`
          flex-grow: 1;
          overflow: scroll;
          position: relative;
          ${bpMaxSM} {
            height: 480px;
          }
        `}
      >
        <ul
          className={css`
            list-style: none;
            position: absolute;
          `}
        >
          {courses
            .filter(course => {
              if (filter === '') {
                return true
              }
              const regex = new RegExp(filter, 'gi')
              return course.title.match(regex)
            })
            .map(course => (
              <CourseItem
                onClick={() => setSelectedCourse(course)}
                isSelected={
                  selectedCourse && course.slug === selectedCourse.slug
                }
                key={course.slug}
                tagIcon={course.square_cover_480_url}
                title={course.title}
                externalLink={course.url}
                concepts={course.skillset_list}
                skillLevel={course.skill_level_list}
                // contentStyle={course.contentStyle}
              />
            ))}
        </ul>
      </div>
    </div>
  )
}

export default CoursesList
