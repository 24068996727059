import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import queryString from 'query-string'
import { css } from 'emotion'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Auth, { USER_KEY } from '../lib/utils/auth'
import Header from './header'
import { UserContext } from '../context/userContext'

const auth = new Auth()

class Layout extends React.Component {
  state = {
    user: {},
    authenticated: false,
  }

  updateUser = () => {
    this.setState({
      user: auth.getUser(),
      authenticated: auth.isAuthenticated(),
    })
  }

  componentDidMount() {
    const queryHash = queryString.parse(window.location.hash)
    const user = localStorage.getItem(USER_KEY)
    const accessToken = get(queryHash, 'access_token')

    if (isEmpty(user)) {
      if (!isEmpty(accessToken)) {
        auth.handleAuthentication(window.location).then(this.updateUser)
      } else {
        this.monitorIntervalId = auth.monitor(this.updateUser)
      }
    } else {
      auth.refreshUser().then(this.updateUser)
      if (!isEmpty(accessToken)) {
        window.history.replaceState({}, document.title, '.')
      }
    }

    this.updateUser()
  }

  componentWillUnmount() {
    if (this.monitorIntervalId) {
      clearTimeout(this.monitorIntervalId)
    }
  }

  render() {
    const { children } = this.props
    return (
      <UserContext.Provider value={this.state}>
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <>
              <Helmet
                title={data.site.siteMetadata.title}
                meta={[
                  { name: 'description', content: 'Sample' },
                  { name: 'keywords', content: 'sample, something' },
                ]}
              >
                <html lang="en" />
              </Helmet>
              <div
                className={css`
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  min-height: 100vh;
                `}
              >
                <Header siteTitle={data.site.siteMetadata.title} />
                <div
                  className={css`
                    flex-grow: 1;
                    width: 100%;
                    padding: 30px 0;
                  `}
                >
                  {children}
                </div>
              </div>
            </>
          )}
        />
      </UserContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
