import React from 'react'
import { css } from 'emotion'
import * as yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { isEmpty } from 'lodash'
import axios from 'axios'
import colorValues from '../../../lib/colorValues'
import { UserContext } from '../../../context/userContext'
import WatchClickOutside from './WatchClickOutside'
import { bpMaxSM, bpDesktopOnly } from '../../../lib/breakpoints'
import Button from '../../Button'

const http = axios.create()
const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('enter your email'),
})

class LoginButton extends React.Component {
  state = {
    clicked: false,
    isSignOnRequested: false,
  }

  setClicked = clicked => this.setState({ clicked })

  render() {
    const { clicked, isSignOnRequested } = this.state
    return (
      <UserContext.Consumer>
        {({ authenticated, subscribed }) => {
          return (
            <Formik
              initialValues={{ email: '' }}
              validationSchema={loginSchema}
              onSubmit={values => {
                this.setState({ isSignOnRequested: true })

                http
                  .post(`${process.env.AUTH_DOMAIN}/api/v1/users/send_token`, {
                    email: values.email,
                    client_id: process.env.CLIENT_ID,
                  })
                  .then(
                    ({ data }) => {
                      if (data.status !== 'sent') {
                        this.setState({ error: true })
                      }
                    },
                    err => {
                      this.setState({ error: true })
                    },
                  )
              }}
              render={({ values }) => {
                return (
                  <>
                    {isSignOnRequested && (
                      <div
                        className={css`
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: center;
                          font-size: 16px;
                          padding: 0;
                          color: #32323b;
                        `}
                      >
                        Check your email for a sign in link.
                      </div>
                    )}
                    {clicked && !isSignOnRequested && (
                      <WatchClickOutside
                        onClickOutside={() => {
                          if (isEmpty(values.email)) {
                            this.setClicked(false)
                          }
                        }}
                      >
                        <div
                          className={css`
                            ${bpMaxSM} {
                              width: 100%;
                              background: white;
                              position: absolute;
                              right: 0;
                              margin-top: -27px;
                              padding: 9px 16px;
                              ${bpMaxSM} {
                                padding: 9px 16px 9px 20px;
                              }
                              display: flex;
                              justify-content: flex-end;
                            }
                          `}
                        >
                          <Form
                            className={css`
                              margin-top: 0;
                              display: flex;
                              flex-direction: column;
                              align-items: flex-end;
                              width: 100%;
                              ${bpDesktopOnly} {
                                max-width: 580px;
                              }
                            `}
                          >
                            <div
                              className={css`
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                max-width: 400px;
                                width: 100%;
                              `}
                            >
                              <Field
                                autoFocus
                                type="email"
                                name="email"
                                id="bd-email"
                                placeholder="Enter your email"
                                required
                                className={css`
                                  border: none;
                                  background-color: rgba(9, 19, 33, 0.06);
                                  color: ${colorValues['gray-darken-20']};
                                  height: 48px;
                                  width: 226px;
                                  display: flex;
                                  flex-direction: column;
                                  justify-content: center;
                                  align-items: left;
                                  padding: 0 10px;
                                  font-size: 16px;
                                  transition: all 600ms
                                    cubic-bezier(0.075, 0.82, 0.165, 1);
                                  &:focus {
                                    outline: transparent;
                                    color: black;
                                    font-weight: 300;
                                    font-size: 1rem;
                                    transition: all 600ms
                                      cubic-bezier(0.075, 0.82, 0.165, 1);
                                  }
                                  &::placeholder {
                                    color: #7f858f;
                                    font-size: 16px;
                                    font-weight: 300;
                                  }
                                `}
                              />
                              <Button blue type="submit" label="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </WatchClickOutside>
                    )}
                    {!clicked && !isSignOnRequested && (
                      <Button blue onClick={() => this.setClicked(true)}>
                        Email Login
                      </Button>
                    )}
                  </>
                )
              }}
            />
          )
        }}
      </UserContext.Consumer>
    )
  }
}

export default LoginButton
