import { useState, useEffect } from 'react'
import { ACCESS_TOKEN_KEY } from './utils/auth'
import { createClient } from 'urql'

export function useClient() {
  const [accessToken, setAccessToken] = useState()
  const [client, setClient] = useState()
  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
    setAccessToken(accessToken)
  })

  useEffect(() => {
    setClient(
      createClient({
        url: process.env.GRAPHQL_DOMAIN,
        fetchOptions: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      }),
    )
  }, [accessToken])
  return [client, accessToken]
}
