import React, { useState, useEffect } from 'react'
import { css } from 'emotion'
import { useStaticQuery, StaticQuery, graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { useMutation, Provider } from 'urql'
import Layout from '../components/layout'
import { Container } from '../components/markupHelpers'
import { bpMaxSM, bpMaxMD } from '../lib/breakpoints'
import Button from '../components/Button'
import { ConceptsFilter, SkillLevelFilter } from '../components/TagsFilters'
import CoursesList from '../components/CoursesList'
import { useClient } from '../lib/useClient'
import { courseMutation } from '../lib/courseMutation'
import InputTitle from '../components/InputTitle'

function ConnectedTagsPage() {
  const { egghead } = useStaticQuery(
    graphql`
      query {
        egghead {
          all_courses {
            title
            slug
            square_cover_480_url
            skillset_list {
              name
            }
            skill_level_list {
              name
            }
          }
        }
      }
    `,
  )

  const [client, accessToken] = useClient()

  if (!client) {
    return null
  }

  return (
    <Provider value={client}>
      <Layout>
        <Container maxWidth={1180}>
          {accessToken ? (
            <TagsPage egghead={egghead} />
          ) : (
            <div>Please sign in to tag courses.</div>
          )}
        </Container>
      </Layout>
    </Provider>
  )
}

const TagsPage = ({ egghead: { all_courses = [] } }) => {
  const [courses, setCourses] = useState(all_courses)
  const [selectedCourse, setSelectedCourse] = useState()
  const [res, executeMutation] = useMutation(courseMutation)

  useEffect(() => {
    if (res.data) {
      const course = get(res, 'data.update_course.course')
      const updatedCourses = map(courses, innerCourse => {
        return innerCourse.slug === course.slug ? course : innerCourse
      })
      setCourses(updatedCourses)
    }
  }, [res])

  const handleSkillLevelSelect = e => {
    const newSelectedCourse = {
      ...selectedCourse,
      skill_level_list: [e.currentTarget.value],
    }

    setSelectedCourse(newSelectedCourse)
  }

  const handleConceptSelect = (skillset_list = []) => {
    const newSelectedCourse = {
      ...selectedCourse,
      skillset_list,
    }

    setSelectedCourse(newSelectedCourse)
  }

  const stringifySelectedCourse = () => {
    const { skill_level_list, skillset_list, slug } = selectedCourse
    return {
      slug,
      skill_level_list: skill_level_list.join(),
      skillset_list: map(skillset_list, 'value').join(),
    }
  }

  const saveSelectedCourse = () => {
    executeMutation(stringifySelectedCourse())
  }

  const saveEnabled =
    selectedCourse &&
    (!isEmpty(selectedCourse.skillset_list) ||
      !isEmpty(selectedCourse.skill_level_list))

  const errors = get(res, 'data.update_course.errors', [])

  return (
    <>
      {selectedCourse && !isEmpty(errors) && (
        <div
          className={css({
            textAlign: 'center',
            marginBottom: 20,
          })}
        >
          There was an error saving {selectedCourse.title}:{' '}
          {errors.map(err => {
            return <p>{err.message}</p>
          })}
        </div>
      )}
      <div
        className={css({
          display: 'flex',
          width: '100%',
          [bpMaxMD]: {
            flexDirection: 'column',
          },
        })}
      >
        <div
          className={css({
            flexShrink: '0',
            width: '50%',
            paddingRight: '30px',
            display: 'flex',
            flexDirection: 'column',
            [bpMaxMD]: {
              width: '100%',
              paddingRight: '0',
            },
          })}
        >
          <CoursesList
            courses={courses}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
          />
        </div>
        <div
          className={css({
            flexShrink: '0',
            width: '50%',
            paddingLeft: '30px',
            [bpMaxMD]: {
              marginTop: '50px',
              width: '100%',
              paddingLeft: '0',
            },
          })}
        >
          <div
            className={css({
              display: 'flex',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              [bpMaxSM]: {
                flexDirection: 'column',
              },
            })}
          >
            <div
              className={css({
                flexShrink: '0',
                height: '6rem',
              })}
            >
              <InputTitle icon="💡" title="concepts" />
              <ConceptsFilter
                course={selectedCourse}
                onChange={handleConceptSelect}
              />
            </div>
            <div
              className={css({
                flexShrink: '0',
                display: 'flex',
                flexDirection: 'column',
                [bpMaxSM]: {
                  width: '100%',
                  marginTop: '30px',
                  paddingLeft: '0',
                },
              })}
            >
              <InputTitle icon="💪🏻" title="skill level" />
              <SkillLevelFilter
                course={selectedCourse}
                onChange={handleSkillLevelSelect}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={css({
          marginTop: 20,
        })}
      >
        <Button blue isDisabled={!saveEnabled} onClick={saveSelectedCourse}>
          Save
        </Button>
      </div>
    </>
  )
}

export default ConnectedTagsPage
